import React from 'react';
import { Outlet, Navigate, useLocation, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setAuthenticationStatus } from '../../redux/actions/authActions';
import Loading from '../shared/Loading/Loading';

const ProtectedRoutes = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const isAuthenticated = useSelector((state) => state.authState.isAuthenticated);
    const token = localStorage.getItem('accessToken') || '';
    const [isLoading, setIsLoading] = React.useState(true);

    const firstLoad = location.state?.from === '/login';

    React.useEffect(() => {
        if (firstLoad) {
            navigate(location.pathname, { replace: true });
        }
    }, [firstLoad, navigate, location.pathname]);

    React.useEffect(() => {
        if (!isAuthenticated && token) {
            dispatch(setAuthenticationStatus(true));
        }

        const timeout = setTimeout(() => {
            setIsLoading(false);
        }, firstLoad ? 2500 : 0);

        return () => clearTimeout(timeout);
    }, [dispatch, isAuthenticated, token, firstLoad]);

    // Render content based on loading state
    return isLoading ? (
        <div><Loading slowLoad={firstLoad} /></div>
    ) : isAuthenticated ? (
        <Outlet />
    ) : (
        <Navigate replace to='/401' />
    );
};

export default ProtectedRoutes;