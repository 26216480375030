import * as React from 'react';
import './Loading.scss'; // Make sure the path to SCSS is correct

export default function Loading({ slowLoad }) {
    
    if (slowLoad) {

        return (
            <div className="loader-container">
              <div className="loader-background">
                <div className="column blue"></div>
              </div>
              <div className="loader-yp-slow"></div>
              <p>Loading YellowPad...</p>
            </div>
          );

    } else {

        return (
            <div className="loader-container">
              <div className="loader-background">
                <div className="column blue"></div>
              </div>
              <div className="loader-yp-fast"></div>
              <p>Loading YellowPad...</p>
            </div>
          );
    }
};
