// axios.js
import axios from 'axios';
/**
 * Axios instance for making HTTP requests.
 *
 * @type {import("axios").AxiosInstance}
 */
const instance = axios.create({
    baseURL: `/api`,
    // baseURL: 'https://yp-web-dev01.dev.yellowpad.ai/api',
    // baseURL: "http://localhost:8002/api",
    // baseURL: `https://yp-node-app-dev01.yellowpad-apps.duplocloud.net`,
    // baseURL: 'https://yp-node-app-dev01.dev.yellowpad.ai',
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        productName: 'Graph-layer'
    }
});

const handleError = (error) => { // Log error to an external logging service // e.g., Sentry, LogRocket handleError(error);
    console.error(error); // Rethrow the error for the caller to handle throw error; };
}
/**
 * API object for making HTTP requests.
 * @namespace Api
 */
const Api = {

    register: async (body) => {
        try {
            const response = await instance.post('/user/createuser', body); return response;
        } catch (error) {
            handleError(error);
        }
    },

    login: async (body) => {
        try {
            const response = await instance.post('/auth/login', body);
            localStorage.setItem("lastDirectory", "/")
            localStorage.setItem("currentUserEmail", response?.data?.user?.email)
            return response;
        } catch (error) {
            handleError(error);
        }
    },

    refreshToken: async (body) => {
        try {
            const response = await instance.post('/auth/refresh', body); return response;
        } catch (error) {
            handleError(error);
        }
    },

    getFolderTree: async () => {
        try {
            const user = localStorage.getItem("username")
            const customer = localStorage.getItem("customerId")
            const response = await instance.get(`/filemanagement/foldertree?user=${user}&customer=${customer}`);
            return response;
        } catch (error) {
            handleError(error);
        }
    },

    shareFileWithUser: async (file, targetUser) => {
        try {
            const customer = localStorage.getItem("customerId")
            const currentUser = localStorage.getItem("username")
            const response = await instance.post(`/filemanagement/permission?authorizeduser=${currentUser}&customer=${customer}&targetuser=${targetUser}&permission=FULL_ACCESS`, {
                path: file
            });
            return response;
        } catch (error) {
            handleError(error);
        }
    },

    getUserList: async () => {
        try {
            const customer = localStorage.getItem("customerId")
            const response = await instance.get(`/user/users?customer=${customer}`);
            return response;
        } catch (error) {
            handleError(error);
        }
    },

    fetchChecklist: async (id) => {
        try {
            const response = await instance.get(`/checklist/getchecklist?id=${id}`);
            return response;
        } catch (error) {
            handleError(error);
        }
    },

    createChecklist: async (body) => {
        const userId = localStorage.getItem('userId');
        try {
            const response = await instance.post(`/checklist/createchecklist?userId=${userId}`, body, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            return response;
        } catch (error) {
            handleError(error);
        }
    },

    moveFilesystemFile: async (source, destination) => {

        const customerId = localStorage.getItem("customerId");
        const username = localStorage.getItem("username");

        try {
            const response = await instance.post(`/filemanagement/movepath?source=${source}&user=${username}&customer=${customerId}&destination=${destination}`);
            return response;
        } catch (error) {
            handleError(error);
        }
    },


    getFileSystemFiles: async (path) => {

        const customerId = localStorage.getItem("customerId");
        const username = localStorage.getItem("username");

        try {
            const response = await instance.get(`/filemanagement/filesandfolders?folder=${path}&user=${username}&customer=${customerId}`);
            return response;
        } catch (error) {
            handleError(error);
        }
    },

    // commneted out getFiles as it is not used in the code
    // getFiles: async (checklistId) => {
    //     const userId = localStorage.getItem('userId');
    //     try {
    //         const response = await instance.get(`/files/getfiles?userId=${userId}`);
    //         return response;
    //     } catch (error) {
    //         handleError(error);
    //     }
    // },

    getRecentFiles: async (user, customer) => {
        try {
            const response = await instance.get(`/filemanagement/recentfiles?user=${user}&customer=${customer}`);
            return response.data;
        } catch (error) {
            console.error('Error fetching recent files:', error);
            throw error;
        }
    },

    getSearchQuery: async (customer, query) => {

        const customerId = localStorage.getItem("customerId");
        const username = localStorage.getItem('username');

        try {
            const response = await instance.get(`filemanagement/search?user=${username}&customer=${customerId}&query=${query}`);
            return response;
        } catch (error) {
            handleError(error);
        }
    },

    deleteFilesystemFile: async (path, user, customer = 'Lucid') => {

        const username = localStorage.getItem('username');
        const customerId = localStorage.getItem("customerId");

        try {
            const response = await instance.delete(`/filemanagement/path?user=${username}&customer=${customerId}`, {
                data: {
                    path: path
                }
            });
            return response;
        } catch (error) {
            handleError(error);
        }
    },

    deleteFile: async (fileId, checklistId) => {
        try {
            const response = await instance.delete(`/files/deletefile?fileId=${fileId}&checklistId=${checklistId}`);
            return response;
        } catch (error) {
            handleError(error);
        }
    },

    updateChecklistItem: async (itemId, fieldValues) => {
        try {
            const response = await instance.put(`/checklistitems/updatechecklistitem?checklistItemId=${itemId}`, fieldValues);
            return response;
        } catch (error) {
            handleError(error);
        }
    },

    getFile: async (filePath, user, customer) => {

        const customerId = localStorage.getItem("customerId");
        const username = localStorage.getItem('username');

        try {
            const response = await instance.get(`/filemanagement/file?file=${filePath}&user=${username}&customer=${customerId}`);
            return response.data;
        } catch (error) {
            handleError(error);
        }
    },

    uploadFile: async (filePath, matterName, checklistName, clientName, checklistType, file, onUploadProgress) => {

        const formData = new FormData();
        formData.append('file', file);

        const username = localStorage.getItem('username');
        const userID = localStorage.getItem('userId');
        const customerId = localStorage.getItem("customerId");

        try {
            const response = await instance.post(
                `/filemanagement/file?path=${filePath}&owner=${username}&userId=${userID}&customer=${customerId}&checklistType=${checklistType}&matterName=${matterName}&checklistName=${checklistName}&clientName=${clientName}`,
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                    onUploadProgress,
                }
            );
            return response.data;
        } catch (error) {
            handleError(error);
            throw error;
        }
    },


    renameFile: async (filePath, renameText) => {

        const username = localStorage.getItem('username');
        const customerId = localStorage.getItem("customerId");

        try {
            const response = await instance.post(`filemanagement/rename?user=${username}&customer=${customerId}`,
                {
                    path: filePath,
                    newName: renameText
                });
            return response.data;
        } catch (error) {
            handleError(error);
            throw error;
        }
    },

    insertItem: async (body) => {
        try {
            const response = await instance.post('/checklist/insertchecklistitem', body);
            return response;
        } catch (error) {
            handleError(error);
        }
    },

    createFolder: async (folderName) => {

        const username = localStorage.getItem('username');
        const customerId = localStorage.getItem("customerId");

        try {
            const response = await instance.post(`/filemanagement/folder?folder=${folderName}&user=${username}&customer=${customerId}`);
            return response;
        } catch (error) {
            handleError(error);
        }
    },

    deleteChecklistItem: async (checklistId) => {
        try {
            const response = await instance.delete(`/checklistitems/disablechecklistitem?checklistId=${checklistId}`);
            return response;
        } catch (error) {
            handleError(error);
        }
    },

    getRiskAnalysisPlaybookPolicy: async (playbookId = "097d429f-31de-4f0c-9aee-fa2605086e13", clasueTopic) => {
        const userId = localStorage.getItem('userId');
        try {
            const response = await instance.get(`/playbookpolicy/playbook-policies?playbookId=${playbookId}&clasueTopic=${clasueTopic}&accountId=${userId}`);
            return response;
        } catch (error) {
            handleError(error);
        }


    },

    getClauseTopics: async () => {
        const userId = localStorage.getItem('userId');
        try {
            const response = await instance.get(`/playbookpolicy/playbook-clause-topics?accountId=${userId}`);
            return response;
        } catch (error) {
            handleError(error);
        }
    },

};

export default Api;
