import React from 'react';
import './EmptyTable.scss'; // Optional: Your custom styles

const EmptyTable = (props) => {
    const { noRowsMessage = 'No Rows Available!' } = props;

    return (
        <div
            role="presentation"
            className="aggrid-common-no-rows-overlay"
            
        >
           {noRowsMessage}
        </div>
    );
};

export default EmptyTable;
