import React, {
    useState,
    useEffect,
    useRef,
    //  useMemo
} from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import "ag-grid-enterprise";
import { useNavigate, useLocation } from "react-router-dom";
import "./style.scss";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import Api from "../../../axios/customAxios";
import { ToastContainer, toast, Bounce } from 'react-toastify';
import EmptyTable from '../../common/aggrid/EmptyTable';

import 'react-toastify/dist/ReactToastify.css';

const handleNotification = (message, isSuccess) => {

    if (isSuccess) {

        toast.success(message, {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
        });

    } else {

        toast.error(message, {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
        });
    }
}

const getTimestamp = () => {

    const now = new Date();

    return now
        .toISOString()
        .replace(/[:\-T.]/g, "")
        .slice(0, 14);
};

const downloadFile = async (url, filename) => {

    try {

        const response = await fetch(url);
        const blob = await response.blob();
        const blobUrl = window.URL.createObjectURL(blob);

        const sanitizedFilename = `${filename}`;

        const link = document.createElement("a");
        link.href = blobUrl;
        link.download = sanitizedFilename;
        link.click();
        window.URL.revokeObjectURL(blobUrl);

    } catch (error) {

        console.error("Error downloading file:", error);
    }
};

const zipFiles = async (fileData, currentPath) => {

    const zip = new JSZip();
    const zipFilename = `yellowpad_${getTimestamp()}.zip`;

    for (const file of fileData) {

        const filePath = `${currentPath}${file.name}`;

        if (file.type === "FOLDER") {

            zip.folder(file.name);

        } else {

            try {

                const userId = localStorage.getItem('userId');
                let customer;
                if (userId === "be4030b1-c840-4164-8e5b-ba944f5399c3") customer = "Cresta";
                else customer = "Lucid";
                const response = await Api.getFile(filePath, localStorage.getItem(""), customer);

                if (response && response.s3url) {

                    const fileUrl = response.s3url.split("?")[0];
                    const fileName = file.name;
                    const fileBlob = await fetch(fileUrl).then((res) => res.blob());

                    zip.file(fileName, fileBlob);

                    const content = await zip.generateAsync({ type: "blob" });
                    saveAs(content, zipFilename);
                }

            } catch (error) {

                console.error(`Error fetching file for zipping: ${filePath}`, error);
            }
        }
    }

};

const Search = () => {

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const navigate = useNavigate();

    const gridApiRef = useRef(null);
    const dropdownRef = useRef(null);

    const [currentPath, setCurrentPath] = useState(decodeURIComponent(searchParams.get("currentPath") || "/"));
    const [selectedCount, setSelectedCount] = useState(0);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [showTrashPopup, setShowTrashPopup] = useState(false);
    const [confirmText, setConfirmText] = useState("");
    const [showDropdown, setShowDropdown] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    const [rowData, setRowData] = useState([]);
    const [hoveredDropdown, setHoveredDropdown] = useState(null);
    const [selectedFilters, setSelectedFilters] = useState([]);
    const [hoveredTerm, setHoveredTerm] = useState(null);
    const [userList, setUserList] = useState([]);

    const termsOptions = [
        { term: "Payment", subOptions: ["Date", "Number", "In Value", "Picklist", "Monetary Amount", "In Topic"] },
        { term: "Confidentiality", subOptions: ["In Value"] },
    ];

    const categoriesOptions = ["Vendor Contract", "MSA", "NDA", "M&A", "Agreement"];
    const ownersOptions = userList;

    useEffect(() => {

        window.addEventListener('error', e => {
            if (e.message.startsWith('ResizeObserver loop')) {
                const resizeObserverErrDiv = document.getElementById(
                    'webpack-dev-server-client-overlay-div'
                );
                const resizeObserverErr = document.getElementById(
                    'webpack-dev-server-client-overlay'
                );
                if (resizeObserverErr) {
                    resizeObserverErr.setAttribute('style', 'display: none');
                }
                if (resizeObserverErrDiv) {
                    resizeObserverErrDiv.setAttribute('style', 'display: none');
                }
            }
        });

        fetchUserList();

        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setHoveredDropdown(null);
            }
        };

        // Add event listener to the document
        document.addEventListener("mousedown", handleClickOutside);

        // Clean up the event listener on unmount
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [])

    const fetchUserList = async () => {

        try {
            const response = await Api.getUserList();

            if (response && response.data) {

                const responseList = response.data?.users;
                setUserList(responseList)

            } else {

                setUserList([]);
            }

        } catch (error) {

            console.error("Error fetching users:", error);
            handleNotification("Failed to fetch users!", false)
        }
    }

    const columnDefs = [
        {
            headerCheckboxSelection: true,
            checkboxSelection: true,
            headerName: "Name",
            field: "file.name",
            flex: 1,
            suppressRowClickSelection: true,
            cellClass: "search-page-ag-row-name",
            autoHeight: true,
        },
        {
            headerName: "Owner",
            field: "file.owner",
            flex: 1,
            suppressRowClickSelection: true,
            cellClass: "search-page-ag-row",
        },
        {
            headerName: "Email",
            field: "file.email",
            flex: 1,
            suppressRowClickSelection: true,
            cellClass: "search-page-ag-row",
        },
        {
            headerName: "Created",
            field: "file.created",
            flex: 1,
            valueFormatter: (params) => {
                const date = new Date(params.value);
                return date.toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                });
            },
        },
        {
            headerName: "Size",
            field: "file.size",
            flex: 1,
            valueFormatter: (params) => `${(params.value / 1024).toFixed(2)} KB`,
        },
        {
            headerName: "Snippet",
            field: "snippet",
            flex: 2,
            cellRenderer: (params) => {
                return (
                    <div
                        className="search-page-ag-row-snippet"
                        dangerouslySetInnerHTML={{ __html: params.value }}
                    />
                );
            },
        },
    ];

    const handleSearchChange = (e) => {

        setSearchQuery(e.target.value);
    };

    const handleSearch = async (e) => {

        if (e.key === "Enter") {

            try {

                const userId = localStorage.getItem('userId');
                let customer;
                if (userId === "be4030b1-c840-4164-8e5b-ba944f5399c3") customer = "Cresta";
                else customer = "Lucid";
                const response = await Api.getSearchQuery(customer, searchQuery);
                setRowData(response.data);

            } catch (error) {

                console.error("Error fetching search results:", error);
            }
        }
    };

    const onRowDoubleClicked = async (params) => {

        const { name, type } = params.data;

        if (type === "FOLDER") {

            setCurrentPath(`${currentPath}${name}/`);

        } else {

            try {

                window.open(
                    `/dashboard/worktable/${params?.data?.checklistId}`,
                    "_blank"
                );

            } catch (error) {

                console.error("Error fetching file:", error);
            }
        }
    };

    const onSelectionChanged = (event) => {
        const selectedRows = event.api.getSelectedRows();
        setSelectedCount(selectedRows.length);
        setSelectedFiles(selectedRows.map((row) => row));
    };

    const handleDownloadClick = async () => {
        if (selectedFiles.length === 0) return;

        const selectedFolders = selectedFiles.filter(
            (file) => file.type === "FOLDER"
        );
        const selectedFileNames = selectedFiles.filter(
            (file) => file.type !== "FOLDER"
        );

        if (selectedFileNames.length === 1 && selectedFolders.length === 0) {
            const file = selectedFileNames[0]?.file;

            try {
                if (file) {
                    let fileUrl = selectedFileNames[0]?.file?.s3url;
                    const originalFileName = file.name;
                    downloadFile(fileUrl, originalFileName);
                } else {
                    console.error("Error: No data returned from API for file download.");
                    alert("File could not be downloaded. Please try again.");
                }
            } catch (error) {
                console.error("Error downloading file:", error);
                alert("File could not be downloaded due to an error.");
            }
        } else {
            await zipFiles(selectedFiles, currentPath);
        }
    };

    const handleMoveToTrashClick = () => {
        if (selectedFiles.length > 0) setShowTrashPopup(true);
    };

    const handleCancelTrash = () => {

        setShowTrashPopup(false);
        setConfirmText("");
    };

    const handleConfirmTrash = async () => {

        if (confirmText.toLowerCase() === "confirm") {

            try {

                const userId = localStorage.getItem('userId');
                let customer;
                if (userId === "be4030b1-c840-4164-8e5b-ba944f5399c3") customer = "Cresta";
                else customer = "Lucid";
                const filePaths = selectedFiles.map((file) => `${currentPath}${file?.name}`);
                const username = localStorage.getItem("username");

                const promises = filePaths.map((filePath) =>
                    Api.deleteFilesystemFile(filePath, username, customer)
                );

                await Promise.all(promises);

            } catch (error) {

                console.error("Error moving files to trash:", error);

            } finally {

                setShowTrashPopup(false);
                setConfirmText("");
            }
        }
    };

    const onGridReady = (params) => {
        gridApiRef.current = params.api;

        const savedState = localStorage.getItem('columnState-Search');
        if (savedState) {
            gridApiRef.current.applyColumnState({
                state: JSON.parse(savedState),
                applyOrder: true,
            });
        }
    };

    const saveColumnState = () => {
        if (gridApiRef.current) {
            const columnState = gridApiRef.current.getColumnState();
            if (columnState) {
                localStorage.setItem('columnState-Search', JSON.stringify(columnState));
            }
        } else {
            console.error('Grid API is not available yet.');
        }
    };


    const handleTermClick = (term, subTerm) => {
        setSelectedFilters((prevFilters) => [
            ...prevFilters,
            { term, subTerm, from: "", to: "", exclude: false },
        ]);
    };

    const handleRemoveFilter = (index) => {
        const updatedFilters = selectedFilters.filter((_, i) => i !== index);
        setSelectedFilters(updatedFilters);
    };

    // const noRowsOverlayComponentParams = useMemo(() => {
    //     return {
    //         noRowsMessageFunc: () => 'No rows found at: ' + new Date().toLocaleTimeString(),
    //     };
    // }, []);

    const getPaymentTermView = (filter, index) => {
        switch (filter.subTerm) {
            case "Number":
                return (
                    <>
                        <div className="search-page-from-to-container-sub">
                            <label>From:</label>
                            <input
                                type="text"
                            />
                        </div>
                        <div className="search-page-from-to-container-sub">
                            <label>To:</label>
                            <input
                                type="text"
                            />
                        </div>
                    </>
                );

            case "In Topic":
                return (
                    <div className="search-page-clause-container">
                        <label>Keyword:{' '}</label>
                        <input
                            className="search-page-keyword-input-filter"
                            type="text"
                        />
                    </div>
                );

            case "Monetary Amount":
                return (
                    <>
                        <div className="search-page-monetary-container">
                            <label>Currency:{' '}</label>
                            <select

                            >
                                <option value="USD">USD</option>
                                <option value="EUR">EUR</option>
                                <option value="GBP">GBP</option>
                            </select>
                        </div>
                        <div className="search-page-from-to-container-sub">
                            <label>From:</label>
                            <input
                                type="text"
                            />
                        </div>
                        <div className="search-page-from-to-container-sub">
                            <label>To:</label>
                            <input type="text" />
                        </div>
                    </>
                );

            case "In Value":
                return (
                    <div className="search-page-text-container">
                        <label>Keyword:{' '}</label>
                        <input className="search-page-keyword-input-filter" type="text" />
                    </div>
                );

            case "Date":
                return (
                    <div className="search-page-date-container">
                        <div className="search-page-date-inputs">
                            <div className="search-page-from-to-container-sub">
                                <label>From:</label>
                                <input
                                    type="date"
                                />
                            </div>
                            <div className="search-page-from-to-container-sub">
                                <label>To:</label>
                                <input
                                    type="date"
                                />
                            </div>
                        </div>
                    </div>
                );

            case "Picklist":
                return (
                    <div>
                        <div className="search-page-picklist-label-main">
                            <label className="search-page-picklist-label">
                                <input
                                    type="checkbox"
                                />
                                Blue
                            </label>
                            <label className="search-page-picklist-label">
                                <input
                                    type="checkbox"
                                />
                                Green
                            </label>
                            <label className="search-page-picklist-label">
                                <input
                                    type="checkbox"
                                />
                                Yellow
                            </label>
                        </div>
                    </div>
                );

            default:
                return <div></div>;
        }
    };

    const getFilterPanel = () => {
        if (selectedFilters.length === 0) {
            return (
                <div className="search-page-filters-title">
                    No terms selected..
                </div>
            );
        } else {
            return selectedFilters.map((filter, index) => (
                <div key={index} className="search-page-filter-box-main">
                    <div className="search-page-filter-box-sub">
                        <div className="search-page-filter-title">
                            <div className="search-page-filter-title-text">{filter.term} - {filter.subTerm}</div>
                            <button className="search-page-filter-close-button" onClick={() => handleRemoveFilter(index)}>x</button>
                        </div>
                        <div className="search-page-from-to-container-main">
                            {filter.term === "Payment" ? (
                                getPaymentTermView(filter, index)
                            ) : (
                                <>
                                    <div className="search-page-text-container">
                                        <label>String:{' '}</label>
                                        <input className="search-page-keyword-input-filter" type="text" />
                                    </div>
                                </>
                            )}
                        </div>
                        <div className="search-page-excludes-label">

                            <div className="search-page-excludes-label-text">
                                <input
                                    type="checkbox"
                                />
                                Exclude
                            </div>
                        </div>
                    </div>
                    {index >= 0 && selectedFilters.length > 1 && index !== selectedFilters.length - 1 && (
                        <div className="search-page-filter-switch">
                            <label className="search-page-toggle-label">AND</label>
                            <label className="search-page-switch">
                                <input
                                    type="checkbox"
                                />
                                <span className="search-page-slider"></span>
                            </label>
                            <label className="search-page-toggle-label">OR</label>
                        </div>
                    )}
                </div>
            ));
        }
    };

    const toggleDropdown = (dropdown) => {
        setHoveredDropdown((prevDropdown) =>
            prevDropdown === dropdown ? null : dropdown
        );
    };

    return (
        <div className="search-page-container">

            <ToastContainer />

            <div className="search-page-title">
                Search <i className="material-icons">search</i>
                <p>This feature is coming soon!</p>
            </div>
            <div className="search-page-bar-container">
                <input
                    type="text"
                    className="search-page-searchbar"
                    placeholder="Search anything..."
                    value={searchQuery}
                    onChange={handleSearchChange}
                    onKeyDown={handleSearch}
                />
            </div>

            <div className="search-page-ag-toolbar">
                <span>{selectedCount} selected</span>
                <button
                    className="search-page-ag-toolbar-button"
                    disabled={selectedCount === 0}
                    onClick={handleDownloadClick}
                >
                    <div className="search-page-ag-toolbar-button-icon">
                        <i className="material-icons">download</i>
                    </div>
                    <div className="search-page-ag-toolbar-button-text">Download</div>
                </button>
                <button
                    className="search-page-ag-toolbar-button"
                    disabled={selectedCount === 0}
                    onClick={() => alert("Share clicked")}
                >
                    <div className="search-page-ag-toolbar-button-icon">
                        <i className="material-icons">share</i>
                    </div>
                    <div className="search-page-ag-toolbar-button-text">Share</div>
                </button>
                <div className="search-page-ag-dropdown-menu">
                    <button
                        className="search-page-ag-dropdown-menu-button"
                        onClick={() => setShowDropdown(!showDropdown)}
                    >
                        <i className="material-icons">more_vert</i>
                    </button>
                    {showDropdown && (
                        <div className="search-page-ag-dropdown-content">

                            <div
                                className="search-page-ag-dropdown-content-row"
                                onClick={handleMoveToTrashClick}
                            >
                                <i className="material-icons">delete</i>Move to Trash
                            </div>
                            <div
                                className="search-page-ag-dropdown-content-row"
                                onClick={() => navigate("/dashboard/trash")}
                            >
                                <i className="material-icons">delete_forever</i>Go to Trash
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <div ref={dropdownRef} className="search-page-dropdown-bar">

                <div className="search-page-dropdown-wrapper">
                    <button
                        className="search-page-dropdown-button"
                        onClick={() => toggleDropdown("terms")}
                    >
                        Terms
                    </button>
                    {hoveredDropdown === "terms" && (
                        <div className="search-page-terms-dropdown">
                            {termsOptions.map((termOption, index) => (
                                <div
                                    key={index}
                                    className="search-page-terms-dropdown-item"
                                    onMouseEnter={() => setHoveredTerm(termOption.term)}
                                    onMouseLeave={() => setHoveredTerm(null)}
                                >
                                    <span className="search-page-term-label">{termOption.term}</span>
                                    <span className="search-page-term-triage">▶</span>

                                    {hoveredTerm === termOption.term && (
                                        <div className="search-page-sub-dropdown">
                                            {termOption.subOptions.map((subOption, subIndex) => (
                                                <div
                                                    key={subIndex}
                                                    className="search-page-sub-dropdown-item"
                                                    onClick={() => handleTermClick(termOption.term, subOption)}
                                                >
                                                    {subOption}
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </div>
                            ))}
                        </div>
                    )}
                </div>

                {/* Categories Dropdown */}
                <div className="search-page-dropdown-wrapper">
                    <button
                        className="search-page-dropdown-button"
                        onClick={() => toggleDropdown("categories")}
                    >
                        Categories
                    </button>
                    {hoveredDropdown === "categories" && (
                        <div className="search-page-terms-dropdown">
                            {categoriesOptions.map((category, index) => (
                                <div key={index} className="search-page-terms-dropdown-item">
                                    {category}
                                </div>
                            ))}
                        </div>
                    )}
                </div>

                {/* Owners Dropdown */}
                <div className="search-page-dropdown-wrapper">
                    <button
                        className="search-page-dropdown-button"
                        onClick={() => toggleDropdown("owners")}
                    >
                        Owners
                    </button>
                    {hoveredDropdown === "owners" && (
                        <div className="search-page-terms-dropdown">
                            {ownersOptions.map((owner, index) => (
                                <div key={index} className="search-page-terms-dropdown-item">
                                    {owner?.email}
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </div>

            <div className="search-page-table-and-filters-container">
                <div className="search-page-filters-container">
                    {getFilterPanel()}
                </div>
                <div id="test" className="ag-theme-alpine">
                    <AgGridReact
                        onGridReady={onGridReady}
                        rowData={rowData}
                        columnDefs={columnDefs}
                        rowSelection="multiple"
                        noRowsOverlayComponent={EmptyTable} // Pass your component directly
                        noRowsOverlayComponentParams={{
                            noRowsMessage: 'No Rows to Show! Search for Results!',
                        }}
                        onRowDoubleClicked={onRowDoubleClicked}
                        animateRows={true}
                        onSelectionChanged={onSelectionChanged}
                        suppressRowClickSelection={true}
                        onColumnMoved={saveColumnState}
                        onColumnVisible={saveColumnState}
                        sideBar={{
                            toolPanels: [
                                {
                                    id: 'columns',
                                    labelDefault: 'Columns',
                                    labelKey: 'columns',
                                    iconKey: 'columns',
                                    toolPanel: 'agColumnsToolPanel',
                                    toolPanelParams: {
                                        suppressRowGroups: true,
                                        suppressValues: true,
                                        suppressPivotMode: true,
                                        suppressPivots: true
                                    }
                                }
                            ]
                        }}
                    />
                </div>
            </div>

            {showTrashPopup && (
                <div className="basepopup-filesystem-overlay">
                    <div className="search-page-trash-popup">
                        <div className="search-page-trash-popup-content">
                            <div className="search-page-trash-popup-title">
                                Confirm Move to Trash
                            </div>
                            <div className="search-page-trash-popup-files">
                                <div className="search-page-trash-popup-files-title">
                                    Files:
                                </div>
                                <div className="search-page-trash-popup-file-list">
                                    {selectedFiles.map((file, index) => (
                                        <div
                                            key={index}
                                            className="search-page-trash-popup-file-item"
                                        >
                                            {`${currentPath}${file.name}`}
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="search-page-trash-popup-confirm">
                                <div className="search-page-trash-popup-confirm-title">
                                    Type "CONFIRM" to Proceed:
                                </div>
                                <input
                                    type="text"
                                    value={confirmText}
                                    onChange={(e) => setConfirmText(e.target.value)}
                                    className="search-page-trash-popup-input"
                                    placeholder="CONFIRM"
                                />
                            </div>
                            <div className="search-page-trash-popup-actions">
                                <button
                                    onClick={handleCancelTrash}
                                    className="search-page-trash-popup-cancel-button"
                                >
                                    Cancel
                                </button>
                                <button
                                    onClick={handleConfirmTrash}
                                    className="search-page-trash-popup-confirm-button"
                                    disabled={confirmText.toLowerCase() !== "confirm"}
                                >
                                    Move to Trash
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Search;
